import { createAsyncThunk } from "@reduxjs/toolkit";
import basicDotnetApiClient from "config/basicDotnetApiClient";
import { toast } from "react-toastify";

export const downloadPacket: any = createAsyncThunk(
  "downloadPacket",
  async (_request: any, { dispatch }) => {
    const { basicAuthToken, userId } = _request;
    try {
      const { data } = await basicDotnetApiClient(false, true, basicAuthToken).get(
        `/v1/management/users/${userId}/packet/marriageaos`,
        {
          responseType: "arraybuffer",
        }
      );
      if (data) {
        var blob = new Blob([data], { type: "application/pdf" });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "Evidence_Form_" + new Date().toISOString().replace(/[:.]/g, "_") + ".pdf";
        link.click();
      }

      return data;
    } catch (error: any) {
      toast.error(error);
    }
  }
);
