import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  verifyAuth: {},
  token: localStorage.getItem("token") || "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setVerifyAuth: (state, { payload }) => {
      state.verifyAuth = payload;
    },
    setToken: (state, { payload }) => {
      state.token = payload;
    },
  },
});

export const { setVerifyAuth, setToken } = authSlice.actions;
export default authSlice.reducer;
