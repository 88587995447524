import { useEffect, useState } from "react";

import CopyBtn from "common/components/CopyBtn";
import chevronDownIcon from "assets/img/icons/chevron-down.svg";
import { useAppDispatch, useAppSelector } from "store/store";
import { createUser, generateToken } from "store/thunk/authThunk";
import { setToken } from "store/slices/authSlice";
import { getUserList } from "store/thunk/userThunk";
import { setUsersList } from "store/slices/userSlice";
import { downloadPacket } from "store/thunk/packetThunk";

const Iframe = () => {
  const baseIframeUrl = window.FORM_UI_URL;
  const dispatch = useAppDispatch();
  const { token } = useAppSelector((state: any) => state.auth);
  const { userList } = useAppSelector((state: any) => state.users);
  const [iframeUrl, setIframeUrl] = useState("");
  const [formState, setFormState] = useState<any>(() =>
    JSON.parse(localStorage.getItem("formState") || "{}")
  );
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isDownloadingPacket, setIsDownloadingPacket] = useState(false);
  const isUserSelected = formState?.userId && formState?.userId !== "new-user";

  const handleInput = ({ target: { name, value } }: any) => {
    setFormState((prev: any) => ({ ...prev, [name]: value?.trim() }));
  };
  useEffect(() => {
    const handleMessage = (event: any) => {
      if (event.data.action === "exitFromIframe") {
        setIframeUrl("");
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    if (formState.billingId && formState.privateKey) {
      const concatString = formState.billingId + ":" + formState.privateKey;
      const encodedString = btoa(concatString);
      setFormState((prev: any) => ({ ...prev, basicToken: encodedString }));
    }
    //eslint-disable-next-line
  }, [formState.billingId, formState.privateKey]);

  const handleIframe = () => {
    token && setIframeUrl(`${baseIframeUrl}?authToken=${token}`);
  };

  useEffect(() => {
    dispatch(setToken(localStorage.getItem("token")));
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    isUserSelected && localStorage.setItem("formState", JSON.stringify(formState));
    //eslint-disable-next-line
  }, [formState]);

  useEffect(() => {
    formState.basicToken && dispatch(getUserList({ basicAuthToken: formState.basicToken }));
    //eslint-disable-next-line
  }, [formState.basicToken]);
  useEffect(() => {
    dispatch(setToken(""));
    if (userList?.length)
      if (isUserSelected) {
        const selectedUser = userList?.find((user: any) => user.id === +formState.userId);
        setFormState((prev: any) => ({ ...prev, email: selectedUser?.email }));
        dispatch(generateToken({ basicAuthToken: formState.basicToken, userId: formState.userId }));
      } else {
        setFormState((prev: any) => ({
          ...prev,
          email: "",
          firstName: "",
          middleName: "",
          lastName: "",
        }));
      }
    //eslint-disable-next-line
  }, [formState?.userId, userList]);

  const handleCreateUser = (e: any) => {
    e.preventDefault();
    const userData = {
      firstName: formState.firstName,
      middleName: formState.middleName,
      lastName: formState.lastName,
      email: formState.email,
    };

    dispatch(createUser({ authToken: formState.basicToken, userData })).then(
      ({ payload: p }: any) => {
        if (p) {
          dispatch(setUsersList([...userList, p]));
          setFormState((prev: any) => ({ ...prev, userId: p?.id }));
        }
      }
    );
  };
  const handleDownloadPacket = () => {
    setIsDownloadingPacket(true);
    dispatch(
      downloadPacket({ basicAuthToken: formState.basicToken, userId: formState.userId })
    ).then(() => {
      setIsDownloadingPacket(false);
    });
  };
  return (
    <section className="p-3 iframe-section">
      <div className="card iframe-form-cover">
        <button
          id="form-details"
          type="button"
          className="row justify-content-between cursor-pointer align-items-center"
          onClick={() => setIsFormOpen((p) => !p)}
        >
          <h6 className="mb-0">Form Details</h6>
          <img src={chevronDownIcon} alt="open" width={16} />
        </button>
        {isFormOpen && (
          <div className="row pt-3">
            <div className="col-md-6">
              <label className="mb-3">
                <span className="label">Billing GUID:</span>
                <input
                  id="billingId"
                  type="text"
                  className="form-control"
                  name="billingId"
                  value={formState.billingId}
                  onChange={handleInput}
                />
              </label>
              <label className="mb-3">
                <span className="label">Private Key:</span>
                <input
                  id="privateKey"
                  type="text"
                  className="form-control"
                  name="privateKey"
                  value={formState.privateKey}
                  onChange={handleInput}
                />
              </label>
              <label className="mb-3">
                <span className="label">Basic Auth Token:</span>
                <input
                  id="basicToken"
                  type="text"
                  className="form-control"
                  name="basicToken"
                  value={formState.basicToken}
                  onChange={handleInput}
                  disabled
                />
                <CopyBtn text={formState.basicToken} id="basicToken_readOnly-copy" />
              </label>
            </div>
            <div className="col-md-6">
              <label>
                <span className="label">User:</span>
                <select
                  id="userId"
                  className="form-select"
                  name="userId"
                  value={formState.userId}
                  onChange={handleInput}
                >
                  <option value="" id="select-user-option">
                    -- Select --
                  </option>
                  <option
                    value="new-user"
                    id="create-user-option"
                    disabled={!formState?.basicToken}
                  >
                    Create New User
                  </option>
                  {userList?.map((user: any) => (
                    <option
                      id={`${user.id}_user-option`}
                      key={user.id}
                      title={user.email}
                      value={user.id}
                    >
                      {`${user.email}`}
                    </option>
                  ))}
                </select>
              </label>
              {formState.userId === "new-user" && (
                <form className="card bg-light mt-3" onSubmit={(e) => handleCreateUser(e)}>
                  <label className="mb-3">
                    <span className="label">First Name:</span>
                    <input
                      id="firstName"
                      type="text"
                      className="form-control"
                      name="firstName"
                      value={formState.firstName}
                      onChange={handleInput}
                      required
                    />
                  </label>
                  <label className="mb-3">
                    <span className="label">Middle Name:</span>
                    <input
                      id="middleName"
                      type="text"
                      className="form-control"
                      name="middleName"
                      value={formState.middleName}
                      onChange={handleInput}
                      required
                    />
                  </label>
                  <label className="mb-3">
                    <span className="label">Last Name:</span>
                    <input
                      id="lastName"
                      type="text"
                      className="form-control"
                      name="lastName"
                      value={formState.lastName}
                      onChange={handleInput}
                      required
                    />
                  </label>
                  <label className="">
                    <span className="label">Email:</span>
                    <input
                      id="email"
                      type="email"
                      className="form-control"
                      name="email"
                      value={formState.email}
                      onChange={handleInput}
                      required
                    />
                  </label>
                  <div className="mt-3 mx-auto">
                    <button id="create-user-btn" type="submit" className="btn btn-success">
                      Create User
                    </button>
                  </div>
                </form>
              )}
            </div>
            <div className="mt-3 text-center mx-auto">
              <button
                id="load-iframe-btn"
                type="button"
                className="btn btn-primary"
                onClick={() => handleIframe()}
                disabled={!token}
              >
                Load Iframe
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="row mt-3">
        <div className="col">
          <label>
            <span className="label col-auto">User ID:</span>
            <input
              id="userId_readOnly"
              type="text"
              className="form-control"
              name="userId"
              value={isUserSelected ? formState.userId : ""}
              disabled
            />
            <CopyBtn text={formState.userId} id="userId_readOnly-copy" />
          </label>
        </div>
        <div className="col">
          <label>
            <span className="label">Email:</span>
            <input
              id="email_readOnly"
              type="text"
              className="form-control"
              name="email"
              value={isUserSelected ? formState.email : ""}
              disabled
            />
            <CopyBtn text={formState.email} id="eamil_readOnly-copy" />
          </label>
        </div>
        <div className="col">
          <label>
            <span className="label">Token:</span>
            <input
              id="token_readOnly"
              type="text"
              className="form-control"
              name="token"
              value={isUserSelected ? token : ""}
              disabled
            />
            <CopyBtn text={token} id="token_readOnly-copy" />
          </label>
        </div>
        <div className="col-auto">
          <button
            id="dowload-packet-btn"
            type="button"
            className="btn btn-primary"
            disabled={!token || isDownloadingPacket}
            onClick={handleDownloadPacket}
          >
            {isDownloadingPacket ? (
              <>
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                <span className="ms-2">Downloading...</span>
              </>
            ) : (
              "Download Packet"
            )}
          </button>
        </div>
      </div>
      <div className="card mt-3 p-0 overflow-hidden" style={{ maxHeight: "calc(100vh - 32px)" }}>
        {iframeUrl ? (
          <iframe
            id="iframe"
            title={`third_party_integration${iframeUrl}`}
            src={iframeUrl}
            style={{ width: "100%", height: "100vh", border: "none" }}
          ></iframe>
        ) : (
          <h5 className="text-center">Load Iframe</h5>
        )}
      </div>
    </section>
  );
};

export default Iframe;
