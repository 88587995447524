import { createAsyncThunk } from "@reduxjs/toolkit";
import basicDotnetApiClient from "config/basicDotnetApiClient";
import { toast } from "react-toastify";
import { setToken } from "store/slices/authSlice";

export const generateToken: any = createAsyncThunk(
  "generateToken",
  async (_request: any, { dispatch }) => {
    const { basicAuthToken, userId } = _request;
    try {
      const { data } = await basicDotnetApiClient(false, true, basicAuthToken).post(
        `/v1/management/users/${userId}/generateToken`
      );
      if (data) {
        localStorage.setItem("token", data?.jwtToken);
        dispatch(setToken(data?.jwtToken));
      }

      return data;
    } catch (error: any) {
      toast.error(error);
    }
  }
);

export const createUser: any = createAsyncThunk(
  "createUser",
  async (_request: any, { dispatch }) => {
    const { authToken, userData } = _request;

    try {
      const { data } = await basicDotnetApiClient(false, true, authToken).post(
        "/v1/management/users",
        userData
      );

      if (data) {
        toast.success("User created successfully");
      } else {
        toast.error("User already exists, please try with different email address");
      }

      return data;
    } catch (error: any) {
      toast.error("User already exists, please try with different email address");
    }
  }
);
