import { createAsyncThunk } from "@reduxjs/toolkit";
import { setLoading, setUsersList } from "store/slices/userSlice";
import basicDotnetApiClient from "config/basicDotnetApiClient";
import { toast } from "react-toastify";

export const getUserList: any = createAsyncThunk(
  "getUserList",
  async ({ basicAuthToken }: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await basicDotnetApiClient(false, true, basicAuthToken).get(
        `/v1/management/users`
      );
      if (data) {
        dispatch(setUsersList(data));
      } else {
        toast.error(data?.message || "Something went wrong");
      }
      return data;
    } catch (error: any) {
      toast.error("Something went wrong");
    } finally {
      dispatch(setLoading(false));
    }
  }
);
