import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: {},
  userList: [],
};

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setUsersList: (state, { payload }) => {
      state.userList = payload;
    },
  },
});

export const { setLoading, setUsersList } = userSlice.actions;
export default userSlice.reducer;
